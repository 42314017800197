import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    BanerParaRest:
      'In the evolving era of disruptive technologies, business leaders require new insights into driving their companies forward. In turn, this generates new corporate spend management strategies to deliver on cost and functional efficiencies.',
    ParaOne:
      'Raindrop uses big data and provides the advanced analytics required to deliver actionable intelligence which each business leader requires for future improvements across their corporate spend, whether at an SMB company, or large enterprise.',
    LeadParaLeftOne: '',
    LeadAnchorPara: 'As per efficio’s Procurement 2025 report,',
    LeadParaRightOne:
      '65% of US procurement leaders think out-of-date systems and processes are a barrier to progress.  More than ever, business leaders are adopting digital technologies to generate insights, which in turn create predictable outcomes.  Given the criticality of digitizing the entire spend management process from planning through renewals, this article covers – ',
    PointAnchorTagOne: 'Introduction to Raindrop Spend Management Solution',
    PointAnchorTagTwo: 'Explore the real benefits of true Digitization with Raindrop ',
    HeadingOne: 'Introduction to Raindrop’s Spend Management Solution',
    ParaTwo:
      'Technology in general, and Spend Management technology specifically, is not just limited to achieving or capturing value.  What today’s companies require are insights capable of driving actions, to help bring the respective company further up the value chain in a repeatable and more efficient manner.  Progressive business leaders are harnessing the capabilities of this digital transformation wave, as a powerful driving factor for bottom-line efficiencies and business transformations.',
    ParaThree:
      'So what is Raindrop?  Simply put, Raindrop is the new standard in Enterprise Spend Management solutions, powered by AI and ML, providing visibility and deep capabilities to controlling ‘how and who’ spends money at your company.  Raindrop is designed by Procurement professionals, and digitizes your Procurement processes, focusing on enabling the creation of predictive outcomes, all while delivering cost savings and cost containment.  This enables businesses to reduce their various digital Procurement point solution systems footprint, while actively streamlining processes, and recouping thousands of dollars in operational and capital expenses.',
    HeadingTwo: 'Explore the real benefits of true digitization with Raindrop',
    ParaFour:
      'Raindrop empowers this move up the value chain by leveraging several factors.  Armed with automation, advanced analytics, and artificial intelligence, Raindrop empowers teams with a true digital spend management solution to manage more complex categories to unlock new areas of value, while keeping downstream transactional activities seamless and efficient. ',
    ParaFive:
      'Raindrop can help you to achieve your digitization goals. The current market demands sourcing to be more integrated across all corporate functions, rendering tactical functions down the list, and moving the CPO, CIO, and other key functions into a company strategic leadership position, delivering value at the highest levels.',

    ParaSix:
      'Raindrop digitizes the complete Source-to-Pay process which is backed by real-time access to market data and trends, advanced analytics, risks, etc., to continue reinforcing the delivery of top-line strategic value.',
    ParaSeven:
      'Utilizing Raindrop’s Machine Learning and Artificial Intelligence inbuilt technologies, you can gain immediate spend visibility and advanced spend intelligence, allowing you to collaborate and deliver on your cost reduction and supplier spend efficiency strategies.  Raindrop’s “Spend Recommendation” technology provides insight into market discounting across a large number of commodities, further enabling efficiencies across your enterprise.  Raindrop ties together all the technologies for effective spend management into an easy-to-use Procurement Resource Management (PRM) platform which enables you to do your business your way.',
    ParaEight:
      'In summary, Raindrop helps solve the “point solution conundrum” which realizes the challenges every business faces with their data siloed across multiple technology platforms, all intent on raising a new level of business leader value delivery.  Moving up the value chain in a strategic and rapid manner by realizing the power of Raindrop.',
    ShiftUrlHref: 'https://www.efficioconsulting.com/en-us/procurement-2025-efficio/',
  },
]
