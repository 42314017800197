import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppDigitizationNeedsRest } from './AppDigitizationNeedsRest'

const AppDigitizationNeedsWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .AppDigitizationNeedsSectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .AppDigitizationNeedsSectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .AppDigitizationNeedsSectionSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppDigitizationNeedsWithNav = () => {
  return (
    <AppDigitizationNeedsWithNavWapper>
      <div className='AppDigitizationNeedsSectionSection'>
        <div className='AppDigitizationNeedsSectionNav'>
          <AppSideNavEmailName />
        </div>
        <div className='AppDigitizationNeedsSectionSectionContent'>
          <AppDigitizationNeedsRest />
        </div>
      </div>
    </AppDigitizationNeedsWithNavWapper>
  )
}
