import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import DigitizationNeedsRestData from '../Data/DigitizationNeedsRestData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppDigitizationNeedsRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .DigitizationNeedsContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .DigitizationNeedsHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .DigitizationNeedsLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .DigitizationNeedsLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 0px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppDigitizationNeedsRest = () => {
  return (
    <AppDigitizationNeedsRestWapper>
      <div>
        {DigitizationNeedsRestData.map((data, index) => {
          return (
            <div className='MainDigitizationNeedsSection' key={index}>
              <div className='DigitizationNeedsContentSectionEmail'>
                <ReUsePtag para={data.BanerParaRest} paraStyle='DigitizationNeedsLeadParaStyleOne' />
                <ReUsePtag para={data.ParaOne} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.LeadParaLeftOne}
                  anchorPara={data.LeadAnchorPara}
                  ParaRight={data.LeadParaRightOne}
                  href={data.ShiftUrlHref}
                  target='_blank'
                  rel='noopener noreferrer'
                  anchorStyle='achorPara'
                  paraStyle='DigitizationNeedsLeadParaStyle'
                />
                <ReUsePtag
                  Icon={data.icon}
                  para={data.PointAnchorTagOne}
                  paraStyle='DigitizationNeedsLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.icon}
                  para={data.PointAnchorTagTwo}
                  paraStyle='DigitizationNeedsLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='DigitizationNeedsHeadingEmail' />
                <ReUsePtag para={data.ParaTwo} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUsePtag para={data.ParaThree} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='DigitizationNeedsHeadingEmail' />
                <ReUsePtag para={data.ParaFour} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUsePtag para={data.ParaFive} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUsePtag para={data.ParaSix} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUsePtag para={data.ParaSeven} paraStyle='DigitizationNeedsLeadParaStyle' />
                <ReUsePtag para={data.ParaEight} paraStyle='DigitizationNeedsLeadParaStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppDigitizationNeedsRestWapper>
  )
}
